body {
  margin: 0;
  font-family: -apple-system, 'Oswald Variable', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.golang {
  fill: #00acd7;
}

.google-st0 {
  fill: #ea4335;
}
.google-st1 {
  fill: #4285f4;
}
.google-st2 {
  fill: #34a853;
}
.google-st3 {
  fill: #fbbc05;
}
